import React from 'react';
import './App.css';
import Home from './pages/home';
import List from './pages/List';
import Chapter from './pages/chapter';
import Comic from './pages/comic';
import { createSelector } from '@reduxjs/toolkit';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navigation from "./components/Navigation";
import {useSelector, useDispatch} from "react-redux";
// import { openChapterPage } from "./services/app/AppSlice";
import {Modal} from "react-bootstrap";
import ChapterPlayerModal from "./components/ChapterPlayerModal";
import AboutPage from "./pages/about";
import RequestPage from "./pages/request";
import Layout from "./components/Layout";
import {FacebookProvider} from "react-facebook";

function App() {


  const page = <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route exact path="/comic/:id">
      <Comic />
    </Route>
    <Route exact path="/chapter/:id">
      <Chapter />
    </Route>
    <Route exact path="/list">
      <List />
    </Route>
    <Route exact path="/ve-chung-toi">
      <AboutPage />
    </Route>
    <Route exact path="/yeu-cau-truyen">
      <RequestPage />
    </Route>
  </Switch>
  return (
    <BrowserRouter>
      <Layout>
        {page}
        <ChapterPlayerModal />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
