import axios from "axios";

const endpoint = window.location.hostname.indexOf('localhost') > -1 ? 'http://comic-audio.local' : 'https://api-fm.ghien.cafe';

const instance = axios.create({
    baseURL: endpoint + '/api/v1/',
    timeout: 30000,
});

export const get = async (url: string) => {
    const res = await instance.get(url)
    return res.data;
}
export const post = async (url: string, data: any) => {
    try {
        const resp = await instance.post(url, data);
        return resp;
    } catch (err) {
        console.error(err);
    }
}