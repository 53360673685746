import React, {useEffect, useState} from "react";
import Navigation from "../components/Navigation";
import {Container, Card, Modal, Row, Col} from "react-bootstrap";
import { useHistory, useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faList, faSquarePollHorizontal, faClose, faHeart, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {get} from "../api/client";
import {setChapterPlaying} from "../services/app/AppSlice";
import {useDispatch} from "react-redux";
import { Helmet } from 'react-helmet';


interface ComicProps {
  name: string,
  description: string,
  image: string,
  chapters: Array<any>,
  genres: Array<any>,
  chapter_total?: number
}

interface ChapterProps {
  name: string,
  content: string,
  duration: string,
  audio_url: string,
}

// @ts-ignore
const Chapter = (props) => {
  const {data} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id : string }>();

  const [chapter, setChapter] = useState<null | ChapterProps>(null);
  const [comic, setComic] = useState<null | ComicProps>(null);

  useEffect(() => {
    console.log('load param')
    const loadChapter = async () => {
      const resp = await get('/chapters/' + params.id)
      setChapter(resp.data)
    }
    loadChapter();

  }, [params.id])

  useEffect(() => {
    console.log('99')
  })

  useEffect(() => {
    console.log('effect')

    if (data) {
      setComic(data.comic)
      setChapter(data)

      return;
    }

    const loadComic = async () => {
      const resp = await get('/comics/' + params.id)
      setComic(resp.data)
    }


    loadComic();

  }, [])

  return (
      <div>
      <div className="appHeader bg-primary text-light">
        <div className="left">
          <a onClick={() => history.goBack()}  className="headerButton goBack">
            {/*<ion-icon name="chevron-back-outline"></ion-icon>*/}
            <FontAwesomeIcon icon={faBackward} className="ion-icon" />
          </a>
        </div>
        <div className="pageTitle">{comic ? comic.name : 'N/A'}</div>
      </div>
      <div id={'appCapsule'}>

        <div className="section full mt-3 mb-3">
          <div style={{
            position: 'relative'
          }}>
            <div className={'comic-background-header'} style={{
              background: `url('${comic ? comic.image : '#'}')`
            }}>
            </div>
            <div className={'comic-thumb-wrap'}>
              <Container fluid>
                <Row className={''}>
                  <Col md={3} xs={3}>
                  </Col>
                  <Col md={6} xs={6}>
                    <img src={comic ? comic.image : ''} className="image  w-100" alt="product image" />
                  </Col>
                  <Col md={3} xs={3}>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>


      <div className="blog-post">
        {/*<div className="mb-2">*/}
        {/*  <div className={'blog-thumb'}>*/}
        {/*  <img src={comic ? comic.thumbnail_url : '#'} alt="image" className="imaged default w-50" />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <h1 className="title mt-3 mb-3">{ chapter ? chapter.name :'' }</h1>

        {/*<div className="post-header">*/}
        {/*  <div>*/}
        {/*    <a href="#">*/}
        {/*      /!*<img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w24 rounded mr-05" />*!/*/}
        {/*        Alex Edwards*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*  Jun 11, 2020*/}
        {/*</div>*/}
        <div className="post-body">
          {chapter && chapter.audio_url && <AudioPlayer
              autoPlay={true}
              loop={false}
              src={chapter.audio_url}
              onPlay={e => {
                dispatch(
                    setChapterPlaying(chapter));
              } }
              onEnded={e => console.log("onEnded")}
              onError={e => console.log("onEnded")}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              //progressJumpSteps={{ backward: 15000, forward: 15000 }}
              // other props here
          />}
          <div>


            <div className="section full mt-2">
              <div className="profile-stats">
                <a href="#" className="item" data-toggle="modal" data-target="#PanelLeft">
                  <strong><FontAwesomeIcon icon={faList} className="ion-icon" /></strong>
                   Danh sách
                </a>
                <a className="item">
                  <strong>1.0x</strong>tốc độ
                </a>
                <a className="item" data-toggle="modal" data-target="#actionTimer">
                  <strong>
                    <FontAwesomeIcon icon={faRotateLeft} className="ion-icon" />
                  </strong>Hẹn giờ
                </a>
                <a className="item" data-toggle="modal"
                   data-target="#actionSheetIconed">
                  <strong>
                    <FontAwesomeIcon icon={faSquarePollHorizontal} className="ion-icon" />
                  </strong>
                    Xem thêm
                </a>
              </div>
            </div>


            {/*<div className="modal fade panelbox panelbox-left" id="PanelLeft"  role="dialog">*/}
              <Modal.Dialog className="modal fade panelbox panelbox-left" id="PanelLeft"  role="dialog">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Danh sách phát</h4>
                      <a href="javascript:;" data-dismiss="modal" className="panel-close">
                        <FontAwesomeIcon icon={faClose} className="ion-icon" />
                      </a>
                    </div>
                    <div className="modal-body">
                      <div>
                        <ul className="listview image-listview flush transparent pt-1">
                          {comic ? comic.chapters.map(chapter => {
                            return  <li>
                              <Link to={'/chapter/' + chapter.id} className="item">
                                <img src="/assets/img/play.png" alt="image" className="image" />
                                <div className="in">
                                  <div>
                                    {chapter.name}
                                    <div className="text-muted">{chapter ? chapter.duration/60 : ''} phút</div>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          }) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Dialog>

            {/*</div>*/}
          </div>
        </div>

        <div className={'section mt-3 full '}>
          <div className={'wide-block pt-2 pb-2'}>
            <div className={'text mt-2 chapter-content'} dangerouslySetInnerHTML={{ __html: comic ? comic.description : 'N/A'}} />
          </div>
        </div>
      </div>
    </div>

      <div className="modal fade action-sheet" id="actionSheetIconed"  role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <ul className="action-button-list">
                <li>
                  <a href="#" className="btn btn-list text-primary" data-dismiss="modal">
                      <span>
                          {/*<ion-icon name="document-outline"></ion-icon>*/}
                        <FontAwesomeIcon icon={faHeart } className="ion-icon mr-2" />
                          Thích
                      </span>
                  </a>
                </li>
                <li className="action-divider"></li>
                <li>
                  <a href="#" className="btn btn-list text-danger" data-dismiss="modal">
                    <span>
                        {/*<ion-icon name="close-outline"></ion-icon>*/}
                      <FontAwesomeIcon icon={faClose} className="ion-icon mr-2" />
                        Đóng
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade action-sheet" id="actionTimer" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <ul className="action-button-list">
                <li>
                  <a href="#" className="btn btn-list text-primary" data-dismiss="modal">
                    <span>Không hẹn giờ</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="btn btn-list" data-dismiss="modal">
                    <span>Khi kết thúc tập này</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="btn btn-list" data-dismiss="modal">
                    <span>15 phút</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="btn btn-list" data-dismiss="modal">
                    <span>30 phút</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="btn btn-list" data-dismiss="modal">
                    <span>60 phút</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="btn btn-list" data-dismiss="modal">
                    <span>90 phút</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="btn btn-list" data-dismiss="modal">
                    <span>120 phút</span>
                  </a>
                </li>
                <li className="action-divider"></li>
                <li>
                  <a href="#" className="btn btn-list text-danger" data-dismiss="modal">
                    <span>Close</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Chapter;