import React, {useEffect, useState, useRef} from "react";
import Navigation from "../components/Navigation";
import {Container, Card, Modal, Row, Col} from "react-bootstrap";
import { useHistory, useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faList, faSquarePollHorizontal, faClose, faHeart,
    faRotateLeft,
    faChevronDown,
    faPlay
} from "@fortawesome/free-solid-svg-icons";
import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer, { RHAP_UI  } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {get} from "../api/client";
import {
    setOpenPlayerModal,
    setChapterIdPlayer,
    setChapterPlaying,
    setOpenTimerModal,
} from "../services/app/AppSlice";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

interface ComicProps {
    name: string,
    description: string,
    image: string,
    chapters: Array<any>,
    genres: Array<any>,
    chapter_total?: number
}

interface ChapterProps {
    name: string,
    content: string,
    duration: number,
    audio_url: string,
    order: number,
    comic: ComicProps
}
// @ts-ignore
let myTimeout = null;

const ChapterPlayerModal = () => {
    const playerRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<{ id : string }>();

    const [chapter, setChapter] = useState<null | ChapterProps>(null);
    const [comic, setComic] = useState<null | ComicProps>(null);
    const [chapterList, setChapterList] = useState<Array<any> | null>(null);
    const [showTimerDialog, setShowTimerDialog] = useState<boolean>(false);
    const [showOtherDialog, setShowOtherDialog] = useState<boolean>(false);
    const [showChapterList, setShowChapterList] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>(0);

    const [modalStage, setModalStage] = useState<string | null>(null);


    const chapterPlayingSelector = createSelector(
        [state => state.app],
        (app) => app.chapterPlaying
    );

    const chapterIdPlayerSelector = createSelector(
        [state => state.app],
        (app) => app.chapterIdPlayer
    );

    const openPlayerModalSelector = createSelector(
        [state => state.app],
        (app) => app.openPlayerModal
    );

    const chapterPlaying = useSelector(
        chapterPlayingSelector
    );

    const chapterIdPlayer = useSelector(
        chapterIdPlayerSelector
    );

    const openPlayerModal = useSelector(
        openPlayerModalSelector
    );

    useEffect(() => {
        // @ts-ignore
        if (myTimeout) {
            clearTimeout(myTimeout);
        }

        if (timer) {
            myTimeout = setTimeout(() => {
                if (playerRef.current) {
                    // @ts-ignore
                    playerRef.current.audio.current.pause();
                    setTimer(0);
                }

            }, timer * 1000);
        }


    }, [timer])


    useEffect(() => {

        if (chapterIdPlayer) {
            const loadChapter = async () => {
                const resp = await get('/chapters/' + chapterIdPlayer)

                if (!resp.data.audio_url) {

                    alert('Chưa có audio')
                    return;
                }

                setChapter(resp.data)
                setComic(resp.data.comic)
                setChapterList(resp.data.comic.chapters)

                dispatch(setChapterPlaying(resp.data));
            }

            loadChapter();
        }

    }, [chapterIdPlayer])

    // useEffect(() => {
    //     console.log('load param')
    //     const loadChapter = async () => {
    //         const resp = await get('http://comic-audio.local/api/v1/chapters/' + params.id)
    //         setChapter(resp.data)
    //     }
    //     loadChapter();
    //
    // }, [params.id])


    useEffect(() => {

        // if (data) {
        //     setComic(data.comic)
        //     setChapter(data)
        //
        //     return;
        // }

        // const loadComic = async () => {
        //     const resp = await get('http://comic-audio.local/api/v1/comics/' + params.id)
        //     setComic(resp.data)
        // }
        //
        // loadComic();

    }, [])


    if (!openPlayerModal) {
        return <div></div>
    }

    let isCompactMode = modalStage === 'compact';

    let playerView = isCompactMode && chapter ? [
            <div className={'rhap_additional-controls'}>{`${isCompactMode
                    ? (chapter.name
                        + (comic  ? ' - ' + comic.name : '')
                    )
                    : '' }`}
            </div>,
            RHAP_UI.MAIN_CONTROLS,
        ] : [
        RHAP_UI.ADDITIONAL_CONTROLS,
        RHAP_UI.MAIN_CONTROLS,
        RHAP_UI.VOLUME_CONTROLS
    ]

    return (
        <div
            onClick={() => {
                if (isCompactMode) {
                    setModalStage('full')
                }
            }}
            className={`chapter-playing-modal ${ isCompactMode ? 'compact-mode' : 'full-mode' }`}
        >
            {!isCompactMode && (
            <div className="appHeader bg-primary text-light">
                <div className="left">
                    <a onClick={() => {
                        setModalStage('compact')
                    } } className="headerButton">
                        <FontAwesomeIcon icon={faChevronDown} className="ion-icon"/>
                    </a>
                </div>
                <div className="pageTitle">{comic ? comic.name : 'N/A'}</div>
            </div>
            )}

            <div id={'appCapsule'}>
                {!isCompactMode && (
                <div className="section full mt-3 mb-3">
                    <div style={{
                        position: 'relative'
                    }}>
                        <div className={'comic-background-header'} style={{
                            background: `url('${comic ? comic.image : '#'}')`
                        }}>
                        </div>
                        <div className={'comic-thumb-wrap'}>
                            <Container fluid>
                                <Row className={''}>
                                    <Col md={3} xs={3}>
                                    </Col>
                                    <Col md={6} xs={6}>
                                        <img src={comic ? comic.image : ''} className="image  w-100"
                                             alt="product image"/>
                                    </Col>
                                    <Col md={3} xs={3}>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
                )}


                <div className="blog-post">
                    {/*<div className="mb-2">*/}
                    {/*  <div className={'blog-thumb'}>*/}
                    {/*  <img src={comic ? comic.thumbnail_url : '#'} alt="image" className="imaged default w-50" />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {!isCompactMode && (
                        <h1 className="title mt-3 mb-3">{chapter ? chapter.name : ''}</h1>
                    )}

                    {/*<div className="post-header">*/}
                    {/*  <div>*/}
                    {/*    <a href="#">*/}
                    {/*      /!*<img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w24 rounded mr-05" />*!/*/}
                    {/*        Alex Edwards*/}
                    {/*    </a>*/}
                    {/*  </div>*/}
                    {/*  Jun 11, 2020*/}
                    {/*</div>*/}
                    <div className="post-body">
                        {chapter && chapter.audio_url && <AudioPlayer
                            // header={`${isCompactMode
                            //     ? (chapter.name
                            //         + (comic  ? ' - ' + comic.name : '')
                            //     )
                            //     : '' }`}
                            ref={playerRef}
                            autoPlay={true}
                            loop={false}
                            src={chapter.audio_url}
                            onPlay={e => {

                            }}
                            onEnded={e => {
                                let currentOrder = chapter.order ?? 1;
                                let nextOrder = currentOrder + 1;

                                let nextChapter:ChapterProps = chapterList ? chapterList.find(item => item.order === nextOrder) : null;
                                if (!nextChapter || !nextChapter.audio_url) {
                                    console.log('end');
                                    return;
                                }

                                console.log('nextChapter', nextChapter)

                                setChapter(nextChapter);
                            }}
                            onError={e => console.log("onEnded")}
                            customAdditionalControls={[]}
                            customVolumeControls={[]}
                            customControlsSection={playerView}
                            //progressJumpSteps={{ backward: 15000, forward: 15000 }}
                            // other props here
                        />}
                        <div>


                            {!isCompactMode && (
                                <div className="section full mt-2">
                                <div className="profile-stats">
                                    <a onClick={() => setShowChapterList(true)} className="item">
                                        <strong><FontAwesomeIcon icon={faList} className="ion-icon"/></strong>
                                        Danh sách
                                    </a>
                                    <a className="item">
                                        <strong>1.0x</strong>tốc độ
                                    </a>
                                    <a className="item" onClick={() => setShowTimerDialog(true)}>
                                        <strong>
                                            <FontAwesomeIcon icon={faRotateLeft} className="ion-icon"/>
                                        </strong>Hẹn giờ
                                        {timer ? ` (${timer / 60} p)` : ''}
                                    </a>
                                    <a className="item" onClick={() => setShowOtherDialog(true)}>
                                        <strong>
                                            <FontAwesomeIcon icon={faSquarePollHorizontal} className="ion-icon"/>
                                        </strong>
                                        Xem thêm
                                    </a>
                                </div>
                            </div>
                            )}


                            {/*<div className="modal fade panelbox panelbox-left" id="PanelLeft"  role="dialog">*/}
                            {showChapterList && (
                            <Modal show={true} className={'modal fade panelbox panelbox-left'} onHide={() => setShowChapterList(false)}>
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Danh sách phát</h4>
                                            <a onClick={() => setShowChapterList(false)} className="panel-close">
                                                <FontAwesomeIcon icon={faClose} className="ion-icon"/>
                                            </a>
                                        </div>
                                        <div className="modal-body">
                                            <div>
                                                <ul className="listview image-listview flush transparent pt-1">
                                                    {chapterList ? chapterList.map(chapter => {
                                                        return <li>
                                                            <a onClick={() => {
                                                                dispatch(setOpenPlayerModal(true))
                                                                dispatch(setChapterIdPlayer(chapter.id))

                                                                setShowChapterList(false)
                                                            }} className="item">
                                                                <FontAwesomeIcon icon={faPlay} className={'image'} />
                                                                <div className="in">
                                                                    <div>
                                                                        {chapter.name}
                                                                        <div
                                                                            className="text-muted">{chapter ? chapter.duration / 60 : ''} phút
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    }) : null}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            )}

                            {/*</div>*/}
                        </div>
                    </div>

                    {!isCompactMode && <div className={'section mt-3 full '}>
                        <div className={'wide-block pt-2 pb-2'}>
                            <div className={'text mt-2 chapter-content'}
                                 dangerouslySetInnerHTML={{__html: comic ? comic.description : 'N/A'}}/>
                        </div>
                    </div> }


                </div>
            </div>
            { showOtherDialog && (
            <Modal show={true} className={'modal fade action-sheet'} onHide={() => setShowOtherDialog(false)}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <ul className="action-button-list">
                                <li>
                                    <a href="#" className="btn btn-list text-primary" data-dismiss="modal">
                                      <span>
                                          {/*<ion-icon name="document-outline"></ion-icon>*/}
                                          <FontAwesomeIcon icon={faHeart} className="ion-icon mr-2"/>
                                          Thích
                                      </span>
                                    </a>
                                </li>
                                <li className="action-divider"></li>
                                <li>
                                    <a href="#" className="btn btn-list text-danger" onClick={() => setShowOtherDialog(false)} >
                                        <span>
                                            {/*<ion-icon name="close-outline"></ion-icon>*/}
                                            <FontAwesomeIcon icon={faClose} className="ion-icon mr-2"/>
                                            Đóng
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
            )}

            <Modal show={showTimerDialog} className={'fade action-sheet'} onHide={() => setShowTimerDialog(false)}>
                <Modal.Body>
                    {/*<div className="modal fade action-sheet" id="actionTimer" role="dialog">*/}
                    {/*    <div className="modal-dialog" role="document">*/}
                    <div className="modal-content">
                        <div className="modal-body">
                            <ul className="action-button-list">
                                <li>
                                    <a onClick={() => {
                                        setTimer(0);
                                        setShowTimerDialog(false);
                                    } } href="#" className="btn btn-list text-primary" data-dismiss="modal">
                                        <span>Không hẹn giờ</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {
                                        setTimer(chapter?.duration ?? 0)
                                        setShowTimerDialog(false);
                                    }}
                                       href="#" className="btn btn-list" data-dismiss="modal">
                                        <span>Khi kết thúc tập này</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {
                                        setTimer(900)
                                        setShowTimerDialog(false);
                                    }} href="#" className="btn btn-list" data-dismiss="modal">
                                        <span>15 phút</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {
                                        setTimer(1800)
                                        setShowTimerDialog(false);
                                    }} href="#" className="btn btn-list" data-dismiss="modal">
                                        <span>30 phút</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {
                                        setTimer(3600)
                                        setShowTimerDialog(false);
                                    }} href="#" className="btn btn-list" data-dismiss="modal">
                                        <span>60 phút</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {
                                        setTimer(5400)
                                        setShowTimerDialog(false);
                                    }} href="#" className="btn btn-list" data-dismiss="modal">
                                        <span>90 phút</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {
                                        setTimer(7200)
                                        setShowTimerDialog(false);
                                    }} href="#" className="btn btn-list" data-dismiss="modal">
                                        <span>120 phút</span>
                                    </a>
                                </li>
                                <li className="action-divider"></li>
                                <li>
                                    <a href="#" className="btn btn-list text-danger" onClick={() => {setOpenTimerModal(false)}}>
                                        <span>Đóng</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/*    </div>*/}
                    {/*</div>*/}
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default ChapterPlayerModal;