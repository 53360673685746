import React from "react";

const AboutPage = () => {
    return (
        <div id={'appCapsule'}>
            <div className="section full mt-2">
                <div className="section-title">Giới thiệu</div>
                <div className="wide-block pt-2 pb-1">
                    <p>
                        Dự án chuyển thể truyện chữ - Audio
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;