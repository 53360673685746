import React from "react";
import {isMobile} from "../helper";
import DesktopNavigation from "./DesktopNavigation";
import Navigation from "./Navigation";

const Layout = ({children}: {children: any}) => {
    if (isMobile) {
        return <div>
            {children}
            <Navigation />
        </div>;
    }
    return <div>
        <div className={'d-flex'}>
            <DesktopNavigation  />
            <div id="content">
                <div className={'container'}>
                    {children}
                </div>
            </div>
        </div>
    </div>
}

export default Layout;