import AppReducer from '../services/app/AppSlice';
import UserReducer from '../services/user/UserSlice';

import { configureStore } from '@reduxjs/toolkit';
const store = configureStore({
    reducer: {
        app: AppReducer,
        user: UserReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
        }),
});
// window.store = store;
export default store;