import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
// import { Link } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faList, faInfo, faMessage } from "@fortawesome/free-solid-svg-icons";

import { useEffect } from "react";
import React from 'react';
import { useHistory, Link } from "react-router-dom";

const Navigation = () => {
  const history = useHistory();

  useEffect(() => {
    console.log('render');
  }, []);

  //
  // const tabs = [{
  //   route: "/",
  //   label: "Trang chủ",
  //   icon: faHome,
  // },
  //   {
  //   route: "/audio",
  //   label: "Audio",
  //   icon: faSearch,
  // }
  // ]

  // @ts-ignore
  // @ts-ignore
  return <div className="appBottomMenu">
    <Link to={'/'} className="item ">
      <div className="col">
        <FontAwesomeIcon icon={faHouse} className="md hydrated ion-icon" />
        <strong>Trang chủ</strong>
        {/*<FaHome className="md hydrated ion-icon" />*/}
        {/*<ion-icon name="home-outline" role="img" className="md hydrated" aria-label="home outline"/>*/}
      </div>
    </Link>
    <Link to={`/yeu-cau-truyen`} className="item">
      <div className="col">
        <FontAwesomeIcon icon={faMessage} className="md hydrated ion-icon" />
        <strong>Yêu cầu</strong>
      </div>
    </Link>
      <Link to={`/ve-chung-toi`} className="item">
        <div className="col">
          <FontAwesomeIcon icon={faInfo} className="md hydrated ion-icon" />
          <strong>Giới thiệu</strong>
        </div>
      </Link>
  </div>

}

export default Navigation