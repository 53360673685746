import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHouse, faList, faInfo, faMessage, faBook } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const DesktopNavigation = () => {
    return <nav className="navbar vertical-nav">
        <div>
            <a className="navbar-brand" href="#">
                <img className={'mr-2'} src={'/assets/img/audio-waves.png'} />
                Comic One
            </a>

        </div>

        <ul className="listview flush transparent no-line image-listview mt-2">
            <li>
                <Link to={'/'} className="item">
                    <div className={'icon-box bg-transparent'}>
                        <FontAwesomeIcon icon={faHouse} />
                    </div>
                    <div className="in">
                        Trang chủ
                    </div>
                </Link>
            </li>

           <li>
               <Link to={`/yeu-cau-truyen`} className="item">
                   <div className={'icon-box bg-transparent'}>
                       <FontAwesomeIcon icon={faMessage} />
                   </div>
                   <div className="in">
                       Yêu cầu
                   </div>
               </Link>
           </li>
            <li>
                <Link to={'/ve-chung-toi'} className="item">
                    <div className={'icon-box bg-transparent'}>
                        <FontAwesomeIcon icon={faInfo} />
                    </div>
                    <div className="in">
                        Giới thiệu
                    </div>
                </Link>
            </li>
            <li>
                <div className="divider bg-secondary mt-2 mb-2"></div>
            </li>
            <li>
                <Link to={`/list`} className="item">
                    <div className={'icon-box bg-blue'}>
                        <FontAwesomeIcon color={'#fff'} icon={faBook} />
                    </div>
                    <div className="in">
                        Truyện nói
                    </div>
                </Link>
            </li>
        </ul>
    </nav>
}

export default DesktopNavigation;
