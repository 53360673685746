import React, {useEffect, useState} from "react";
import Navigation from "../components/Navigation";
import {Container, Card, Row, Col, Modal} from "react-bootstrap";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward, faRotateLeft, faChevronLeft, faHeadphonesSimple, faPlay } from "@fortawesome/free-solid-svg-icons";
import {get} from "../api/client";
import { IoGridOutline, IoPlay } from "react-icons/io5";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useDispatch} from "react-redux";
import { setOpenPlayerModal, setChapterIdPlayer } from "../services/app/AppSlice";
import Loading from "../components/Loading";
import {APP_URL} from "../constances";
import {Comments, FacebookProvider} from "react-facebook";
import { Helmet } from 'react-helmet';

interface ComicProps {
  name: string,
  description: string,
  image: string,
  chapters: Array<any>,
  genres: Array<any>,
  chapter_total?: number
}

const Comic = () => {
  const history = useHistory();
  const params = useParams<{ id : string }>();
  const dispatch = useDispatch();
  const location = useLocation();

  const [comic, setComic] = useState<null | ComicProps>(null);
  const [relatedComics, setRelatedComics] = useState<null | Array<{
    image: string,
    name: string,
    duration: string,
  }>>(null);

  const getRelatedComics = async() => {
    const resp = await get('/comics')
    setRelatedComics(resp.data)
  }

  useEffect(() => {
    const load = async () => {
      const resp = await get('/comics/' + params.id)
      setComic(resp.data)
    }

    load();

    getRelatedComics();

  }, [])

  return (
    <div>
      <div className="appHeader bg-primary text-light">
        <div className="left">
          <a onClick={() => history.goBack()}  className="headerButton goBack">
            {/*<ion-icon name="chevron-back-outline"></ion-icon>*/}
            <FontAwesomeIcon icon={faChevronLeft} className="ion-icon" />
          </a>
        </div>
        <div className="pageTitle">
          {comic ? comic.name : 'N/A'}
            </div>
      </div>
      <div id={'appCapsule'}>

        {/*<div className="section full mt-3 mb-3">*/}
        {/*  <div style={{*/}
        {/*    position: 'relative'*/}
        {/*  }}>*/}
        {/*    <div className={'comic-background-header'} style={{*/}
        {/*      background: `url('${comic ? comic.thumbnail_url : '#'}')`*/}
        {/*    }}>*/}
        {/*    </div>*/}
        {/*    <div className={'comic-thumb-wrap'}>*/}
        {/*    <Container fluid>*/}
        {/*    <Row className={''}>*/}
        {/*      <Col md={6} xs={6}>*/}
        {/*        <img src={comic ? comic.thumbnail_url : ''} className="image  w-100" alt="product image" />*/}
        {/*      </Col>*/}

        {/*      <Col md={6} xs={6}>*/}
        {/*        <h3 className="card-title">{comic ? comic.name : 'N/A'}</h3>*/}
        {/*        <h6 className="card-subtitle">*/}
        {/*          Thể thoại: #audio*/}
        {/*          {comic ? comic.genres.map(genre => {*/}
        {/*            return ` #${genre.name}`*/}
        {/*          }) : null}*/}
        {/*        </h6>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    </Container>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="section mt-3 mb-3">
          <div className="card">


              <div className={'d-flex justify-content-center'}>
               <div className={'row'}>
                 <div className={'col-md-6 col-12 col-sm-12'}>
                   <img src={comic ? comic.image : '#'} className="card-img-top" alt="image" />
                 </div>
               </div>
              </div>


            <div className="card-body">
              <h6 className="card-subtitle">
                #audio
                {comic ? comic.genres.map(genre => {
                  return ` #${genre.name}`
                }) : null}
              </h6>
              <h5 className="card-title">{comic ? comic.name : 'N/A'}</h5>

              <div className="section full">
                <div className="profile-stats">
                  <a className="item">
                    <strong>152</strong>likes
                  </a>
                  <a className="item">
                    <strong>{comic ? comic.chapters.length : 0}</strong>tổng chap
                  </a>
                  <a className="item">
                    <strong>27k</strong>followers
                  </a>

                  <a className="item">
                    <strong>506</strong>following
                  </a>
                </div>
              </div>

              <div className="section full">
                <div className="wide-block transparent p-0">

                  <Tabs
                      defaultActiveKey="summary"
                      className="nav nav-tabs lined iconed"
                  >
                    <Tab eventKey="summary" title="Giới thiệu">
                            <div className="mt-2 ">
                              <div className={'text comic-description'} dangerouslySetInnerHTML={{ __html: comic ? comic.description : 'N/A'}} />
                            </div>
                    </Tab>
                    <Tab eventKey="chapter_list" title={`Chap (${comic ? comic.chapters.length : 0})`}>
                            <ul className="listview image-listview flush transparent pt-1">
                              {comic ? comic.chapters.map(chapter => {
                                return  <li>
                                  {/*<Link to={'/chapter/' + chapter.id} className="item">*/}
                                  <a onClick={() => {
                                    dispatch(setOpenPlayerModal(true))
                                    dispatch(setChapterIdPlayer(chapter.id))
                                  }} className="item">
                                    {/*<img src="/assets/img/play.png" alt="image" className="image" />*/}
                                    <FontAwesomeIcon icon={faPlay} className={'image'} />
                                    <div className="in">
                                      <div>
                                        {chapter.name}
                                        <div className="text-muted">{chapter ? chapter.duration/60 : ''} phút</div>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              }) : null}
                            </ul>
                    </Tab>
                    <Tab eventKey="comment" title="Comment">
                      <div className="mt-2 mb-2">
                        <div className="widget widget-comment  strap-comment">
                          <div className="fb-comments">
                            <FacebookProvider appId="1405680763254532">
                              <Comments colorScheme="dark"  href={ APP_URL + location.pathname } />
                            </FacebookProvider>
                          </div>
                        </div>
                      </div>
                    </Tab>

                  </Tabs>
                </div>
              </div>


              <div className="section full mt-2 mb-3">
                <div className="section-title mb-1">Truyện khác</div>

                <div className="carousel-multiple owl-carousel owl-theme">
                  {relatedComics ? relatedComics.map(item => {
                    return <div className="item">
                      <div className="card product-card">
                        <div className="card-body">
                          <img src={item.image} className="image" alt="product image" />
                          <h2 className="title">{item.name}</h2>
                          <p className="text">{item.duration}</p>
                          <a href="#" className="btn btn-sm btn-primary btn-block">Nghe</a>
                        </div>
                      </div>
                    </div>
                  }) : null}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comic;