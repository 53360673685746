import React, {useEffect, useState} from "react";
import Navigation from "../components/Navigation";
import { Container, Card, Modal, Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { get } from '../api/client';
import compareVersions from 'compare-versions';

const List = () => {
    const history = useHistory();
    const [comics, setComics] = useState(null || Array<any>);
    const [modal, setModal] = useState(null );

    useEffect(() => {
        const load = async () => {
            const resp = await get('/comics')
            setComics(resp.data)
        }

        load();


        get('app-configs').then(res => {
            if (res.error) {
                return
            }

            const {
                android_version,
                ios_version,
                notification
            } = res.data
            //const version = Platform.OS === 'ios' ? ios_version : android_version

            if (notification) {
                // @ts-ignore
                setModal(<Modal show={true} onHide={() => setModal(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Thông báo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModal(null)}>
                            Đóng
                        </Button>
                    </Modal.Footer>
                </Modal>);
            }
        })


    }, [])

    return (
        <div id={'appCapsule'}>
            <div className="header-large-title">
                <h1 className="title">Danh sách</h1>
            </div>
            <div className="section mt-3 mb-3">
                <ul className="listview image-listview media">
                    {comics && comics.length > 0 ? comics.map(comic => {
                        return (
                            <li>
                                <div onClick={() => history.push(`/comic/${comic.id}`) } className="item">
                                    <div className={'imageWrapper'}>
                                        <img src={comic.image} alt="image" className="imaged w100" />
                                    </div>
                                    <div className="in">
                                        <div>
                                            <header>#audio</header>
                                            {comic.name}
                                            <footer>Tình trạng: { comic.stage }</footer>
                                            <footer>Lượt nghe: 10,8k</footer>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    }) : 'loading' }
                </ul>
            </div>

            {modal}
        </div>
    )
}

export default List;