import {
    createSlice, PayloadAction
} from '@reduxjs/toolkit';

const AppSlice = createSlice({
    name: 'app',
    initialState: {
        chapterPlaying: {},
        chapterIdPlayer: null,
        playerStatus: 0,
        openPlayerModal: false,
        openTimerModal: false,
    },
    reducers: {
        setChapterPlaying(state, action: PayloadAction<object>) {
            state.chapterPlaying = action.payload
        },
        setChapterIdPlayer(state, action: PayloadAction<number|null>) {
            // @ts-ignore
            state.chapterIdPlayer = action.payload
        },
        setOpenPlayerModal(state, action: PayloadAction<boolean>) {
            state.openPlayerModal = action.payload
        },
        setOpenTimerModal(state, action: PayloadAction<boolean>) {
            state.openTimerModal = action.payload
        },

    },
})

export const {
    setChapterPlaying,
    setOpenPlayerModal,
    setChapterIdPlayer,
    setOpenTimerModal,
} = AppSlice.actions
export default AppSlice.reducer