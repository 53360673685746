import {
    createSlice, PayloadAction
} from '@reduxjs/toolkit';

const UserSlice = createSlice({
    name: 'user',
    initialState: {
        userInfo: {}
    },
    reducers: {
        setUserInfo(state, action: PayloadAction<object>) {
            state.userInfo = action.payload
        },
    },
})

export const { setUserInfo } = UserSlice.actions
export default UserSlice.reducer