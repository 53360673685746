import React from "react";

const RequestPage = () => {
    // @ts-ignore
    return (
        <div id={'appCapsule'}>
            <div className="section full mt-2">
                <div className="section-title">Yêu cầu truyện AUDIO</div>
                <div className="wide-block pt-2 pb-1">
                    <iframe
                        style={{width: '100%'}}
                        src="https://docs.google.com/forms/d/e/1FAIpQLSdtY9OtRqDBLsLU6XSGfbOSe9AswH4lbbSt8mtviJylunTdFA/viewform?embedded=true"
                        width="640" height="874" >Đang tải…
                    </iframe>
                </div>
            </div>
        </div>
    )
}

export default RequestPage;